
import ProgressPhotoForm from "@/components/progressPhoto/ProgressPhotoForm"
import { mapStores } from "pinia"
import { useProgressPhotoStore } from "@/stores/progressPhotos"

export default {
  components: {
    ProgressPhotoForm,
  },
  data() {
    return {
      hasLoaded: false,
      progressPhoto: {},
      initialProgressPhoto: {
        cameraExids: [],
        provider: "",
        config: {},
        notifyTime: null,
        timezone: null,
        notifyDays: [],
      },
    }
  },
  computed: {
    ...mapStores(useProgressPhotoStore),
    progressPhotoTitle() {
      return this.progressPhotoStore.isEditDialog
        ? this.$t("content.progress_photo.edit")
        : this.$t("content.progress_photo.new")
    },
  },
  async created() {
    this.initialProgressPhoto.timezone = this.$moment.tz.guess()
    if (
      this.progressPhotoStore.progressPhotoId &&
      this.progressPhotoStore.isEditDialog
    ) {
      await this.progressPhotoStore.getCurrentProgressPhoto(
        this.progressPhotoStore.progressPhotoId
      )
      await this.showEditRequest(this.progressPhotoStore.currentProgressPhoto)
    } else {
      this.hasLoaded = true
    }
  },
  methods: {
    async showEditRequest(progressPhoto) {
      ;[progressPhoto] = progressPhoto
      const stringToArray = (text) => text?.split(",") || ""

      this.progressPhoto = {
        cameraExids: progressPhoto.cameras.map((camera) => camera.exid),
        provider: progressPhoto.provider,
        config: progressPhoto.config,
        recipients: progressPhoto.config.recipients,
        notifyDays: stringToArray(progressPhoto.notifyDays),
        notifyTime: progressPhoto.notifyTime,
        timezone: progressPhoto.timezone,
        isPaused: progressPhoto.isPaused,
      }
      this.hasLoaded = true
    },
  },
}
